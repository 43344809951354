/** @format */

import React from 'react';

import Layout from '../components/layout';
import SmallPhoto from '../components/SmallPhoto';
import SmallerPhoto from '../components/SmallerPhoto';
import Photo from '../components/Photo';
// import Text from "../components/Text"
import Title from '../components/Title';
import ProjectBar from '../components/ProjectBar';
// import Test from '../components/test.md';
import headerPhoto from '../images/liveunlimitedh.png';
import smallPhoto1 from '../images/liveunlimited1.png';
import smallPhoto2 from '../images/liveunlimited2.png';
import smallPhoto3 from '../images/lu7.png';
import smallPhoto4 from '../images/liveunlimited4.png';
import smallPhoto6 from '../images/lu6.png';
import smallPhoto7 from '../images/lu9.png';
import smallPhoto8 from '../images/liveunlimited8.png';
import smallPhoto9 from '../images/lu10.png';
import smallPhoto10 from '../images/lu8.png';
import smallPhoto11 from '../images/lu5.png';
import smallPhoto12 from '../images/liveunlimited16.png';
import smallPhoto13 from '../images/liveunlimited17.png';
import smallPhoto14 from '../images/lu3.3.png';
import smallPhoto15 from '../images/lu4.png';
import smallPhoto16 from '../images/lu2.3.png';
import luia from '../images/luia.jpg';
import iterations from '../images/iterations.png';
import luheader from '../images/luheader.png';

import { navigate } from 'gatsby-link';

import lu from '../images/liveunlimitedc.png';
import mb from '../images/mallardbayc.png';
import cms from '../images/cybercmscover.png';
import dhl from '../images/dhl1.png';

const LU = () => {
    return (
        <Layout>
            {/* <ProjectBar /> */}
            <Title
                title="Live Unlimited"
                subtitle="Creating Community and Promoting Charity"
                categories="UI/UX Design, Development"
            />
            <Photo link={luheader} />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Overview</h2>
                        <h3 className="first">Roles</h3>
                        <p className="firstp">
                            UI/UX Design, Front-End Development
                        </p>
                        <h3>Client</h3>
                        <p className="firstp">
                            Live Unlimited is a Vancouver based community for
                            students and young professionals to discover new
                            career opportunities while empowering their ability
                            to give through Charitable Impact.
                        </p>
                        <h3>Goal</h3>
                        <p className="firstp">
                            Build a platform to act as a central hub for the
                            members of the Live Unlimited community.
                        </p>
                        <h3>Solution</h3>
                        <p className="firstp">
                            A mobile application that allows users to create a
                            profile, make posts to a community feed, learn about
                            and RSVP to events, and find and chat with other
                            users in the community.
                        </p>
                        <p>
                            Along with a web based admin portal that allows
                            admins to create events and manage users.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h2>Approach</h2>
                        <h3 className="first">Gathering Requirements</h3>
                        <p className="firstp">
                            We began by working closely with the founders to
                            understand their goals for the application. From the
                            get go they had a very good idea of the main
                            features they wanted in their app. Our job was to
                            help fill in the gaps.
                        </p>
                        <p>
                            Using the information we gathered and working
                            alongside the developers we created a document
                            listing out their requested features and the
                            features we added to support their ideas.
                        </p>
                        <h3>Information Architecture</h3>
                        <p className="firstp">
                            To better visualize the application I took the
                            features document and turned it into an information
                            architecture diagram.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={luia} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h2>Design Process</h2>
                        <h3 className="first">Design Goals</h3>
                        <p className="firstp">
                            The goal was to design an interface that fostered
                            communication and connection between members of the
                            Live Unlimited community.
                        </p>
                        <h3>Iterations</h3>
                        <p className="firstp">
                            The client already had a few sample designs for some
                            sections of the application. A handful of the
                            onboarding screens looked ready to go into
                            prototyping, but for most screens we started with
                            sketches because we saw gaps in their designs.
                        </p>
                        <p>
                            For instance, on the events page, each event was
                            represented by only an image and the event's name.
                            We sketched it out to include more information such
                            as dates, times, and number of attendees so that the
                            user could see more information instantly.
                        </p>
                    </div>
                </div>
            </div>
            <SmallerPhoto
                link={smallPhoto1}
                subtitle="Original Onboarding and Profile Concept Mock-Ups From Client"
                link2={smallPhoto12}
                subtitle2="Early Sketches of Events, Messages, and Wallet Pages"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <p>
                            Another one of their designs that needed work was
                            the profile page.
                            <strong>
                                The app's goal was to create connections, but
                                users' info was hidden behind buttons and not
                                easily visible.
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Increasing Profile Info Visibility</h3>
                        <p className="firstp">
                            They really liked the idea of having the text
                            hidden. So we first tried to compromise by placing
                            the text behind an accordion and leaving the first
                            answer expanded. That way users could instantly read
                            one of the profile’s answers and if interested they
                            could continue to look at the others.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={smallPhoto16} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <p className="">
                            Presenting the example with the accordion opened the
                            client up to the idea of not having the text hidden.
                            So we took a step further and removed the accordion,
                            and just displayed text.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={smallPhoto14} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Promoting Individuality</h3>
                        <p className="firstp">
                            We then experimented with a couple different header
                            options.
                        </p>
                        <p>
                            We tested options with potential users and found
                            users preferred the ability to choose their own
                            header photo, especially since the background color
                            of their profile was assigned by their level and
                            could not be customized.
                        </p>
                    </div>
                </div>
            </div>
            <Photo link={smallPhoto15} subtitle="" />
            {/* <Photo link={iterations} /> */}
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <p>
                            After aligning on our design that achieved our goals
                            of displaying a user's information prominently and
                            allowing them to express individuality we added the
                            user’s passions at the top of their profile as a
                            quick way to see if this was someone you may want to
                            connect with.
                        </p>
                    </div>
                </div>
            </div>
            <Photo link={smallPhoto11} subtitle=" " />

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h2>User Research</h2>
                        <p className="firstp">
                            We led usability testing sessions where we had
                            participants use the Think-Aloud approach while
                            fulfilling specific tasks.
                        </p>
                        <p>
                            Through testing we found that:
                            <ul>
                                <li>
                                    Users would likely drop off during
                                    onboarding because of the hard questions
                                    that needed to be answered
                                </li>
                                <li>
                                    Users indicated that they wanted to answer
                                    the questions, but felt doing it all during
                                    sign up was very burdensome
                                </li>
                            </ul>
                        </p>
                        <p>
                            As a result of our findings, we:
                            <ul>
                                <li>
                                    Added a skip option to the onboarding
                                    process to increase user retention
                                </li>
                                <li>
                                    Ensured users could easily add/edit their
                                    question answers in their profile page
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
            <Photo link={smallPhoto6} subtitle="Sign Up Questions" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h2>Development</h2>
                        <h3 className="first">Component Library</h3>
                        <p className="firstp">
                            To assist in development first I broke the designs
                            apart into reusable components within XD.
                        </p>
                        <p>
                            Then while the other developers set up the back-end
                            I coded the components in React Native and started
                            wiring up the front-end.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto
                link={smallPhoto13}
                subtitle="Part of the Component Library"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h2>Design</h2>
                        <h3 className="first">Final Designs</h3>
                        <p className="firstp">
                            Below are a selection of screens from the final
                            application.
                        </p>
                    </div>
                </div>
            </div>
            <Photo link={smallPhoto3} subtitle="Sign Up Process Intro" />
            <br></br>
            <br></br>
            <Photo link={smallPhoto10} subtitle="Community Section" />
            <br></br>
            <br></br>
            <Photo
                link={smallPhoto7}
                subtitle="Event Section & Events Details Page"
            />
            <br></br>
            <br></br>
            <Photo link={smallPhoto9} subtitle="Messages Section" />
            {/* <br></br>
            <br></br>
            <SmallPhoto link={smallPhoto8} subtitle="Wallet Section" /> */}
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h2>Reflection</h2>
                        <h3>Key Learnings</h3>
                        <p>
                            <ul className="firstp">
                                <li>
                                    When working with international clients, be
                                    aware that accessibility laws can change and
                                    design accordingly. In this case, Canadian
                                    accessibility laws are much stricter than
                                    the USA's.
                                </li>
                                <li>
                                    Conduct user testing earlier. Test designs
                                    throughout the process with users, not just
                                    near and at the end.
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2></h2>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* <ProjectBar /> */}
            <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/cybercms/`)}>
                            <img className="project-photo" src={cms}></img>
                            <div className="project-name">Cyber CMS</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/mallardbay/`)}>
                            <img className="project-photo" src={mb}></img>
                            <div className="project-name">Mallard Bay</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img className="project-photo" src={dhl}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default LU;

// <div className="section">
// <div className="container">
//     <div className="small-photo-wrapper">
//         <h3>Usability Testing</h3>
//         <p>
//             For this portion, I was not the lead, but I helped
//             with creating the questions for users, conducting
//             the testing, and incorporating the feedback.
//         </p>
//         <p>
//             <b>
//                 We used the Think-Aloud Approach with specific
//                 tasks to fulfill.
//             </b>
//         </p>
//         <p>
//             <b>Goals:</b>
//         </p>
//         <ul>
//             <li>Test the navigability of the app</li>
//             <li>
//                 Identify any pain points and confusion in the
//                 flow
//             </li>
//             <li>
//                 Get non-stakeholder feedback on the design of
//                 the app
//             </li>
//         </ul>

//         <h4>Feedback</h4>

//         <p>
//             We received feedback in two main areas of the
//             application: onboarding and the wallet.
//         </p>
//         <h4>Onboarding Feedback</h4>
//         <p>
//             The feedback we recieved indicated that the users
//             felt overwhelmed at some of the questions. They
//             indicated that they would like to answer them, but
//             at their own pace and being forced to answer them
//             before even entering the app could deter them from
//             using it at all. So we added a skip button.
//         </p>
//         <h4>Wallet Feedback</h4>
//         <p>
//             Initially when a user donated points we indicated it
//             by using a downward pointing red arrow in their
//             transaction record, our testers pointed out that
//             this made them feel that the donation was a negative
//             action. In response, we changed the graphic to a
//             gold star to remove the negative connotation.
//         </p>
//     </div>
// </div>
// </div>
