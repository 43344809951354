/** @format */

import React from 'react';
import { ImageZoom } from './ImageZoom';

const SmallerPhoto = (props) => (
    <div className="section">
        <div className="container">
            <div className="smaller-photo-wrapper">
                <div className="smaller-photo">
                    <ImageZoom
                        className="photo"
                        src={props.link}
                        alt="Mock-Up"></ImageZoom>
                    <div className="subtitle">{props.subtitle}</div>
                </div>
                <div className="smaller-photo">
                    <ImageZoom
                        className="photo"
                        src={props.link2}
                        alt="Sketch"></ImageZoom>
                    <div className="subtitle">{props.subtitle2}</div>
                </div>
            </div>
        </div>
    </div>
);

export default SmallerPhoto;
